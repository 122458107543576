import { useState, useCallback, useEffect } from "react";
import CryptoJS from "crypto-js"; /* CryptoJS */
export const Decryption = (data) => {
  try {
    if (process.env.REACT_APP_ENC_KEY) {
      const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_ENC_KEY);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } else return data;
  } catch (e) {
    return false;
  }
};
export const Encryption = (data) => {
  if (process.env.REACT_APP_ENC_KEY)
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENC_KEY
    ).toString();
  else return data;
};

export function useForceUpdate() {
  const [, setUpdate] = useState(0);
  const update = useCallback(() => {
    setUpdate((update) => update + 1);
  }, []);
  return update;
}
